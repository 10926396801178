.Card {
    aspect-ratio: 2 / 3 !important;
    border: 1px solid gray;
}

.FrontSide {
    @extend .Card;
    background-image: url('../assets/images/cardbackground.jpg');
    background-size: cover;
    // padding: 10px;
}

.BackSide {
    @extend .Card;
    background-image: url('../assets/images/cardback.jpg');
    background-size: cover;
}

.card-ref {
    height: 100%;
    width: 100%;
}

.card-title {
    // position: absolute;
    // right: 5%;
    // left: 5%;
    // top: 5%;
    height: 5%;
    width: 100%;

    margin: 0;
}

.card-image {
    border: 1px solid black;

    // position: absolute;
    // right: 5%;
    // left: 5%;
    // top: 10%;
    height: 40%;
    width: 100%;

    margin: 0;
}

.card-subtitle {
    // position: absolute;
    // right: 5%;
    // left: 5%;
    // top: 50%;
    height: 5%;
    width: 100%;

    margin: 0 !important;
}

.card-text {
    border: 1px solid black;
    background-color: #f3e2cb;

    // position: absolute;
    // right: 5%;
    // left: 5%;
    // top: 55%;
    height: 50%;

    margin: 0;
    padding: 0.5em;
    text-align: center;
    overflow: hidden;
}