.Menu {
    position: absolute;
    display: flex;  
  
    height: 100%;  
    left: 0;
    right: 0;
  
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }
  
  .Menu Button {
    margin: 1em;
  }